import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import ContactForm from '../../components/ContactForm'


const ContactPage = ({ data }) => {

  return (
    <Layout pageData={data.strapiContact}>
      <Seo title={data.strapiContact.title} />
      <div className="container">
        <ContactForm />
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    strapiContact {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`
